export default {
  siteTitle: 'Get bonus',
  blogerName: 'UFC',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/UFC335'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c6cce1147',
      gameTitle: 'Savage Buffalo Spirit Megaways (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>For the first deposit</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/ca27908f3',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>For the first deposit</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c97ed0181',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cd9f4948a',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ce1cf8a45',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c94dffdb0',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cd5485b51',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c7688c7c2',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
  ],
  headerText: 'Use promo <strong>UFC</strong> to get 50FS in the slots below!',
  yourPromocode: 'Your promo code',
  gameBonus: 'Bonus in',
  promocode: 'UFC',
  casinoBonus1: '<strong>100% + 500FS</strong><div>For the first deposit</div>',
  casinoBonus2: '<strong>50FS</strong><div>For the sign up with the promo code</div>',
  firstDeposit: 'For the first deposit',
  copyMessage: 'Promo code copied',
  theme: 'dark'
};
